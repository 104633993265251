import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { IconArrowRight } from '@redteclab/icons'

import { Link } from 'base-ui'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokSectionHeadingType } from '../../model/storyblokTypes.generated'

export const StoryblokSectionHeading: FC<
  StoryblokComponentProps<StoryblokSectionHeadingType>
> = ({ blok }) => {
  const { showMoreLink, showMoreText, tag, text } = blok

  if (!tag) {
    return null
  }

  const Element = tag

  return (
    <div
      className="flex flex-col gap-1 desktop:flex-row desktop:items-center desktop:justify-between desktop:gap-4"
      {...storyblokEditable(blok)}
    >
      <Element className="text-xl font-medium desktop:text-2xl">{text}</Element>
      {showMoreLink?.url ? (
        <Link
          className="flex gap-1 whitespace-nowrap text-s font-medium no-underline desktop:text-m"
          color="inherit"
          href={showMoreLink.url}
        >
          {showMoreText}
          <IconArrowRight className="size-6 text-dark-brand" />
        </Link>
      ) : null}
    </div>
  )
}
